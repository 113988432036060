import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

import Bugsnag from "@bugsnag/js";

import { Box, Typography, Tabs, Tab, Stack, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EditOrganization from "./admin/EditOrganization";
import EditQuestions from "./admin/EditQuestions";

import UserData from "../../../data/UserData";
import OrganizationData from "../../../data/OrganizationData";

import useOrganizationData from "../../../hooks/useOrganizationData";

import Emitter from "../../../Emitter";
import EditLabels from "./admin/EditLabels";
import PricingPage from "../../../components/organisms/PricingPage";
import IntegrationPage from "../../../components/organisms/IntegrationPage";
import useOrganizationSubscription from "../../../hooks/useOrganizationSubscription";
import EditProgressPhotos from "./admin/EditProgressPhotos";
import useEvents from "../../../hooks/useEvents";
// import EditSupplements from "./admin/EditSupplements";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const AdminPage = () => {
  const uid = localStorage.getItem("uid");
  const navigate = useNavigate();

  const organizationData = useOrganizationData({ uid });
  const subscribeOrg = useOrganizationSubscription({ uid });

  const tabIndexValue = localStorage.getItem("adminPage_TabIndex");
  const events = useEvents();
  const [tabIndex, setTabIndex] = React.useState(
    tabIndexValue ? parseInt(tabIndexValue, 10) : 0,
  );

  const userRef = React.useRef();

  React.useEffect(() => {
    return () => {
      if (navigate.action === "POP") {
        navigate("/home");
      }
    };
  }, [navigate]);

  React.useEffect(() => {
    firebase.analytics().logEvent("page_view", {
      page_title: "Admin Page",
      page_location: "/admin",
    });
  }, []);

  React.useEffect(() => {
    async function getData() {
      let userData;

      try {
        userData = await UserData.getUserData(uid);

        if (!userData) {
          navigate("/home");
          return;
        }
      } catch (error) {
        Bugsnag.notify(error);
      }

      try {
        userRef.current = userData;
      } catch (error) {
        Bugsnag.notify(error);

        localStorage.removeItem("uid");
        localStorage.removeItem("email");
        localStorage.removeItem("organizationId");
        await firebase.auth().signOut();
        navigate("/home");
      }
    }
    getData();
  }, [history, uid]);

  const handleChange = (event, newValue) => {
    const tabChangeEvent = {
      pageName: "admin",
      tabName: newValue,
      lastTab: tabIndex,
    };
    events.logTabSwitch(tabChangeEvent);
    setTabIndex(newValue);
    localStorage.setItem("adminPage_TabIndex", newValue);
  };

  return (
    <Box style={{ marginTop: 20 }}>
      <Box>
        <Typography variant="h5">Administration</Typography>
        <Divider style={{ marginTop: 20, marginBottom: 20 }}></Divider>
      </Box>
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label="General"></Tab>
        <Tab label="Report Questions"></Tab>
        <Tab label="Daily Questions"></Tab>
        <Tab label="Progress Photos"></Tab>
        <Tab label="Labels"></Tab>
        <Tab label="Integration"></Tab>
        {/* <Tab label="View Subscription"></Tab> */}
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <EditOrganization
          userData={userRef.current}
          orgData={organizationData}
          saveOrgData={async (data) => {
            await OrganizationData.saveOrganizationData(
              userRef.current.uid,
              data,
            );
            Emitter.emit("orgChanged");
          }}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <EditQuestions type="weekly" />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <EditQuestions type="daily" />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <EditProgressPhotos />
      </TabPanel>

      <TabPanel value={tabIndex} index={4}>
        <EditLabels />
      </TabPanel>

      <TabPanel value={tabIndex} index={5}>
        <Stack>
          <Box style={{ height: 600, backgroundColor: "white", padding: 24 }}>
            <IntegrationPage organizationData={subscribeOrg} />
          </Box>
        </Stack>
      </TabPanel>

      {/* <TabPanel value={tabIndex} index={6}>
        <Stack>
          <Box style={{ height: 600, backgroundColor: "white", padding: 24 }}>
            <PricingPage organizationData={subscribeOrg} />
          </Box>
        </Stack>
      </TabPanel> */}
    </Box>
  );
};

export default AdminPage;
