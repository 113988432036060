import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import Bugsnag from "@bugsnag/js";
import { getFirestore, onSnapshot, collection } from "firebase/firestore";
import { useRecoilValue, atom, useRecoilState } from "recoil";
import _ from "underscore";
import { consola } from "consola";
import usersState from "../atoms/usersAtom";
import organizationIdState from "../atoms/organizationIdAtom";
import userDataState from "../atoms/userDataAtom";

export const ClientSubscriptionFilters = {
  all: "all",
  active: "active",
  disabled: "disabled",
};

export const clientTableFilterState = atom({
  key: "clientTableFilterState",
  default: ClientSubscriptionFilters.active,
});

export const aggregatedClientsState = atom({
  key: "aggregatedClientsState",
  default: [],
});

function findUser(uid, users) {
  return _.findWhere(users, { uid: uid });
}

/**
 * Aggregates client data by fetching additional user data from Firestore if not cached.
 *
 * @param {Array} clientData - An array of client data documents.
 * @returns {Promise<Array>} A promise that resolves to an array of aggregated and sorted client data.
 *
 * @example
 * const clientData = [
 *   { data: () => ({ uid: 'user1', firstName: 'John' }) },
 *   { data: () => ({ uid: 'user2', firstName: 'Jane' }) }
 * ];
 * const result = await AggregateClientsData(clientData);
 * console.log(result);
 */
const AggregateClientsData = async (clientData, users) => {
  const aggregatedPromises = clientData.map(async (doc) => {
    const clientValues = doc.data();
    const cachedUser = findUser(clientValues.uid, users);

    if (cachedUser) {
      return {
        ...cachedUser,
        ...clientValues,
      };
    }

    try {
      const ref = firebase
        .firestore()
        .collection("users")
        .doc(clientValues.uid);
      consola.info("++++++ READ -> ClientData: AggregateClientData");
      const userData = await ref.get();

      if (userData.exists) {
        const userDataValues = userData.data();
        return {
          ...userDataValues,
          ...clientValues,
        };
      } else {
        Bugsnag.notify(new Error("User data not found getting client data."));
        return null;
      }
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  });

  const results = await Promise.allSettled(aggregatedPromises);
  const aggregated = results
    .filter(
      (result) =>
        result.status === "fulfilled" &&
        result.value !== null &&
        result.value.isDeleted !== true,
    )
    .map((result) => result.value);

  return _.sortBy(aggregated, "firstName");
};

const useClientsAggregate = ({ includeDisabled = true } = {}) => {
  const [aggregatedClients, setAggregatedClients] = useRecoilState(
    aggregatedClientsState,
  );
  const [loading, setLoading] = useState(true);
  const users = useRecoilValue(usersState);
  const coach = useRecoilValue(userDataState);
  const filter = useRecoilValue(clientTableFilterState);
  const organizationId = useRecoilValue(organizationIdState);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const db = getFirestore();
    let clientRef = collection(db, "organizations", organizationId, "clients");

    if (filter === ClientSubscriptionFilters.all && coach.role === "admin") {
      clientRef = firebase
        .firestore()
        .collection("organizations")
        .doc(organizationId)
        .collection("clients");
    } else {
      if (filter === ClientSubscriptionFilters.all) {
        clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients")
          .where("coaches", "array-contains", coach.uid);
      } else if (filter === ClientSubscriptionFilters.active) {
        clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients")
          .where("coaches", "array-contains", coach.uid)
          .where("status", "==", ClientSubscriptionFilters.active);
      } else if (filter === ClientSubscriptionFilters.disabled) {
        clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients")
          .where("coaches", "array-contains", coach.uid)
          .where("status", "==", ClientSubscriptionFilters.disabled);
      } else {
        clientRef = firebase
          .firestore()
          .collection("organizations")
          .doc(organizationId)
          .collection("clients")
          .where("coaches", "array-contains", coach.uid)
          .where("status", "==", ClientSubscriptionFilters.active);
      }
    }

    const unsubscribe = onSnapshot(clientRef, async (snapshot) => {
      consola.info(
        "++++++ READ -> ClientData: useClientsAggregate - new snapshot",
      );
      const aggregated = await AggregateClientsData(snapshot.docs, users);
      consola.info(
        "++++++ READ -> ClientData: useClientsAggregate",
        aggregated,
      );
      setAggregatedClients(aggregated);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [organizationId, includeDisabled, filter]);

  return { aggregatedClients, loading };
};

export default useClientsAggregate;
